import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

const DepartmentSelector = ({ open, setOpen, departmentObjs, selectionHandler }) => {


    // const [department, setDepartment] = useState("");
    const [department, setDepartment] = useState("CLS");


    const [error, setError] = useState(false);


    const departmentObjsCLS = {"CLS": departmentObjs["CLS"]};


    const performSelection = () => {
        if (!department) {
            setError(true); // Trigger error if no department selected
            return;
        }
        selectionHandler(departmentObjs[department], department);
        setOpen(false);
    };

    return (
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <MDTypography variant="h5" color="info">

                    {/*Please select your School or Department:*/}
                    Department:

                </MDTypography>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth error={error} sx={{ mt: 2 }}>


                    {/*<InputLabel*/}
                    {/*    id="department-label"*/}
                    {/*    sx={{ backgroundColor: "#FFFFFF", paddingLeft:"5px", paddingRight:"5px" }}*/}
                    {/*>*/}
                    {/*    School or Department*/}
                    {/*</InputLabel>*/}


                    <Select
                        labelId="department-label"
                        id="department"


                        // value={department}
                        value={"CLS"}


                        onChange={(e) => {
                            setDepartment(e.target.value);
                            setError(false); // Reset error when selection changes
                        }}
                        label="Department"
                        sx={{ height: 50, fontSize: '1rem', width: '100%' }}
                        aria-labelledby="department-label"
                        aria-describedby="department-helper-text"
                    >
                        {
                            // Object.keys(departmentObjs).map(k => (<MenuItem key={k} value={k}>{k}</MenuItem>))
                            Object.keys(departmentObjsCLS).map(k => (<MenuItem key={k} value={k}>{k}</MenuItem>))
                        }
                    </Select>
                    {error && <FormHelperText id="department-helper-text">Department is required</FormHelperText>}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <MDButton onClick={performSelection}>


                    {/*<MDTypography variant="h6" color="info">Ok</MDTypography>*/}
                    <MDTypography variant="h6" color="info">Proceed</MDTypography>


                </MDButton>
            </DialogActions>
        </Dialog>
    );
};

export default DepartmentSelector;
