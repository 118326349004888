import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import DeviceMap from "../../../device-overview/DeviceMap/DeviceMap";

const mapModal = ({device, open, handleClose}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '80%',
        bgcolor: 'background.paper',
        borderRadius: 4,
        boxShadow: 24,
        p: isMobile ? 2 : 4,
    };
    //
    // useEffect(() => {
    //     // console.log(device);
    // },[device]);

    // const selectDevice = async (device) => {
    //     const currentTime = new Date();
    //     const endTime = currentTime.toISOString();
    //     const startTime12Hour = new Date(currentTime.getTime() - 12 * 60 * 60 * 1000).toISOString();
    // };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Icon>close</Icon>
                </IconButton>
                <DeviceMap devices={[device]} />
            </Box>
        </Modal>
    )
}

export default mapModal;